import {makeAutoObservable, runInAction, toJS} from "mobx";
import axiosInstance from "./http/axiosInstance";
import {makePersistable} from "mobx-persist-store";
import config from "./config.json";

export class ProjectState {
    pageScrollDisabled = true;

    debitBestProducts = undefined;
    creditBestProducts = undefined;
    mortgageBestProducts = undefined;
    depositBestProducts = undefined;
    microloanBestProducts = undefined;

    debitProductsForEachBank = undefined;
    creditProductsForEachBank = undefined;
    mortgageProductsForEachBank = undefined;
    microloanProductsForEachBank = undefined;
    depositProductsForEachBank = undefined;

    productsLoading = false;

    getProductsForEachBank = (category) => this[`${category}ProductsForEachBank`]
    setProductsForEachBank = (category, products) => {
        this[`${category}ProductsForEachBank`] = products
    }

    productsPageData = {
        general: 0,
        size: 7,
        page: 0
    };

    currentProduct = undefined;

    getBestProducts = (category) => this[`${category}BestProducts`]
    setBestProducts = (category, products) => {
        this[`${category}BestProducts`] = products
    }


    activeCategories = [];
    allCategoriesChecked = false;
    categoriesCheckStarted = false;

    curSpecificBank = undefined;
    curSpecificBankProducts = undefined;

    constructor() {
        if (window.Telegram.WebApp.initDataUnsafe.user) {
            this.userTgId = window.Telegram.WebApp.initDataUnsafe.user.id;
        }
        makeAutoObservable(this)
        makePersistable(this, {
            name: 'ProjectState',
            storage: window.localStorage,
            properties: [
                'debitBestProducts',
                'creditBestProducts',
                'mortgageBestProducts',
                'microloanBestProducts',
                'depositBestProducts',
                "productsPageData",
                "currentProduct",
                "debitProductsForEachBank",
                "creditProductsForEachBank",
                "mortgageProductsForEachBank",
                "microloanProductsForEachBank",
                "depositProductsForEachBank",
                "userTgId",
                "activeCategories",
                "allCategoriesChecked",
                "categoriesCheckStarted"
            ],
            expireIn: config.cacheSettings.ttl,
            removeOnExpiration: true
        })
    }

    updateProducts = async (category, key, bank = undefined) => {
        if (typeof this.userTgId !== "number") return
        const params = {
            user_id: this.userTgId,
            product_type: category,
            size: this.productsPageData.size,
            page: this.productsPageData.page
        }
        let url;
        if (bank) {
            params.bank = bank;
            url = `/products/all-for-one-bank/`;
            params.sort = "commission desc";
        } else {
            url = `/products/best-for-each-bank/`
            params.sort = "desc";
        }

        this.productsLoading = true;
        const response = await axiosInstance.get(url, {params})
        runInAction(() => {
            this[key] = response.data.results;
            this.productsPageData.general = response.data.general;
            this.productsPageData.size = response.data.size;
            this.productsLoading = false;
        })
    }


    getProducts = async (viewType, category, page, size=7,bank = undefined) => {
        const productsEmpty =
            viewType === "bestForEach"
                ?
                this.getProductsForEachBank(category) === undefined
                :
                this.getBestProducts(category) === undefined;
        const key = `${category}${viewType === "allForEach" ? "ProductsForEachBank" : "BestProducts"}`
        runInAction(()=>{
            this.productsPageData.size = size
        })
        if (
            toJS(this.productsPageData.page) !== page
            ||
            productsEmpty
        ) {
            this.productsPageData.page = page;
            await this.updateProducts(category, key, bank);
        }
        return this[key];
    }
}