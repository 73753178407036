import axios from "axios";
import {dynamicConfig} from "../dynamicConfig";

let axiosInstance = axios.create({
    baseURL: `${dynamicConfig.backend.host}/api/v1`,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    },
    withCredentials: true
});

export default axiosInstance;