import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./theme";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Root} from "./pages/Root";
import {MainPage} from "./pages/MainPage";
import {ProductsNavigationPage} from "./pages/ProductsNavigationPage";
import {BanksPage} from "./pages/BanksPage";
import {ProductsCategoriesPage} from "./pages/ProductsCategoriesPage";
import {ProductItem} from "./pages/ProductItem";
import {ProductsList} from "./pages/ProductsList";
import "./fonts/tinkoff/style.css";


function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Root/>}>
                        <Route index element={<MainPage/>}/>
                        <Route path={"/products-categories"} element={<ProductsCategoriesPage />} />
                        <Route path={"/products-navigation"} element={<ProductsNavigationPage/>} />
                        <Route path={"/banks"} element={<BanksPage/>}/>
                        <Route path={"/products/:productItemId"} element={<ProductItem/>}/>
                        <Route path={"/products/"} element={<ProductsList/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
