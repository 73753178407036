import React, {useEffect} from 'react';
import {
    BottomNavigation,
    Box, Chip,
    Divider,
    Fade,
    List,
    ListItemButton,
    ListSubheader,
    Pagination,
    Paper,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ProjectState} from "../state";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import config from "../config.json";
import {BackButton} from "@vkruglikov/react-telegram-web-app";

const projectState = new ProjectState();

export const BanksPage = observer(() => {
    const [query, setQuery] = useSearchParams()
    const category = query.get("category");
    const pageType = query.get("viewType");
    const categoryObj = config.categories[category]
    const navigate = useNavigate();
    const [products, setProducts] = React.useState([]);
    let page = query.get("page");
    page = page ? page * 1 : 1;
    const Category = () => (
        <Stack direction={"row"} position={"relative"}>
            <Typography
                variant={"bold"}
                style={{alignSelf: "center"}}
                color={"gray"}
            >
                {categoryObj?.name}
            </Typography>
            <Typography
                variant={"h5"}
                style={{marginLeft: "1vh", alignSelf: "center"}}
            >
                {categoryObj?.icon}
            </Typography>
            <Chip label={"Подарок"} style={{position: "absolute", right: "2vh", top: "1vh", width: "25vw", fontSize: "15px", backgroundColor: "#fcce03", fontWeight: "bold", border: "4px solid lightgray"}}/>
        </Stack>
    );

    const handlePageChange = (event, value) => {
        query.set("page", value);
        setQuery(query);
    };

    const BottomPagination = () => {
        const ObservedPagination = observer(
            () => {
                let count = Math.ceil(projectState.productsPageData.general / projectState.productsPageData.size)
                return <Pagination
                    count={count}
                    page={toJS(projectState.productsPageData.page)}
                    onChange={handlePageChange}
                    style={{alignSelf: "center"}}
                />
            }
        )
        return (
            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                <BottomNavigation>
                    <ObservedPagination/>
                </BottomNavigation>
            </Paper>
        );
    };

    const BankButton = ({productObj, children}) => {
        let url;
        switch (query.get("viewType")) {
            case ("bestForEach"):
                url = `/products/${productObj?.id}?category=${query.get("category")}&viewType=${pageType}`;
                break;
            case ("allForEach"):
                url = `/products/?category=${category}&bank=${productObj?.bank}`;
                break;
        }
        return (
            <ListItemButton
                onClick={() => navigate(url)}
                children={children}
            />
        );
    }

    useEffect(() => {
        projectState.getProducts(
            "bestForEach",
            category,
            page
        )
            .then(products_ => {
                setProducts(products_)
            })
    }, [null, query, projectState.userTgId]);

    return (
        <Box margin={"2vh"}>
            <BackButton onClick={() => navigate(`/products-navigation/?category=${category}`)}/>
            <Stack width={"100%"}>
                <Typography variant={"h4"}>
                    {config.bank.title}
                </Typography>
                <Divider/>
                <Category/>
                <Box marginTop={"2vh"}>
                    <Paper>
                        {
                            projectState.productsLoading ?
                                <Fade
                                    in={toJS(projectState.productsLoading)}
                                    style={{
                                        transitionDelay: toJS(projectState.productsLoading) ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <div style={{margin: "2vh"}}>
                                        {[...Array(5)].map(() => <Skeleton variant="text"
                                                                           sx={{fontSize: '3rem'}}/>)}
                                    </div>
                                </Fade>
                                :
                                <div>
                                    <List>
                                        {
                                            products?.length === undefined || products?.length === 0
                                                ?
                                                <ListSubheader>Нет доступных банков</ListSubheader>
                                                :
                                                products?.map(
                                                    (productObj) => {
                                                        return (<>
                                                            <BankButton productObj={productObj}>
                                                                <Typography variant="h6">
                                                                    {productObj.bank}
                                                                </Typography>
                                                                <Chip
                                                                    label={`${String(productObj.commission).match(/\d{1,3}(?=(\d{3})*$)/g).join(" ")} руб.`}
                                                                    style={{right: "2vh", position: "absolute", fontSize: "1em", width: "25vw", border: "2px solid #fcce03"}}
                                                                />
                                                            </BankButton>
                                                            {products.indexOf(productObj) !== Object.keys(products).length - 1 &&
                                                                <Divider style={{marginLeft: "2vw"}}/>}
                                                        </>);
                                                    }
                                                )
                                        }
                                    </List>
                                </div>
                        }
                    </Paper>
                </Box>
            </Stack>
            <BottomPagination/>
        </Box>
    );
});