import React, {useEffect} from 'react';
import {Box} from "@mui/material";
import {Outlet} from "react-router-dom";
import {ProjectState} from "../state";
import {observer} from "mobx-react-lite";

const projectState = new ProjectState();
export const Root = observer((props) => {
    return (
        <Box marginBottom={"2vh"} overflow={"hidden"}>
            <Outlet/>
        </Box>
    );
});