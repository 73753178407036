import React, {useEffect} from 'react';
import {Box, CircularProgress, Divider, ListItemButton, Paper, Stack, Typography} from "@mui/material";
import config from "../config.json";
import {useNavigate} from "react-router-dom";
import {ProjectState} from "../state";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import {useInitData} from "@vkruglikov/react-telegram-web-app";

const projectState = new ProjectState()

export const GoToProductCategoryList = observer(() => {
    let navigate = useNavigate();
    const [initDataUnsafe, initData] = useInitData();

    const GoToProductItem = ({icon, text}) => (<>
        <Typography
            variant={"h4"}
            style={{marginRight: "1vh"}}
        >
            {icon}
        </Typography>
        {text}
    </>);

    const handleItemClick = (item) => {
        navigate(`/products-navigation/?category=${item.id}`);
    };

    const waitForTgId = async () => {
        while (!toJS(projectState.userTgId)) {
            console.log("Tg Id not defined")
            await new Promise(r => setTimeout(r, 100));
        }
    }

    useEffect(() => {
        // if (projectState.categoriesCheckStarted === true) {
        //     return
        // }

        waitForTgId().then(
            () => {
                projectState.categoriesCheckStarted = true;
                console.log(`Start products checking for ${projectState.userTgId}`)
                Object.keys(config.categories).forEach(
                    (category) => {
                        projectState.getProducts("bestForEach", category, 1)
                            .then(
                                (products) => {
                                    if (products?.length > 0 && !projectState.activeCategories.includes(category)) {
                                        projectState.activeCategories.push(category)
                                    }
                                    if (Object.keys(config.categories).indexOf(category) === Object.keys(config.categories).length - 1) {
                                        projectState.allCategoriesChecked = true;
                                    }
                                }
                            );
                    }
                )
            }
        )
    }, [null, projectState.userTgId]);

    return (
        <Box margin={"2vh"}>
            <Box margin={"1vh 0"}>
                <Typography variant={"h6"} style={{padding: "1vh"}}>
                    {config.mainPage.title}
                </Typography>
            </Box>
            <Box>
                <Paper>
                    {
                        projectState.activeCategories.map(
                            (key) => {
                                const category = config.categories[key];
                                return (<>
                                    {projectState.activeCategories.indexOf(key) !== 0 &&
                                        <Divider style={{marginLeft: "2vw"}}/>}
                                    <ListItemButton onClick={() => {
                                        handleItemClick(category)
                                    }}>
                                        <GoToProductItem
                                            icon={category.icon}
                                            text={category.goToText}
                                        />
                                    </ListItemButton>
                                </>)
                            }
                        )
                    }
                </Paper>
                {
                    !projectState.allCategoriesChecked ?
                        <Stack alignItems={"center"} marginTop={"2vh"}>
                            <CircularProgress sx={{color: "gray"}}/>
                        </Stack>
                        :
                        null
                }
            </Box>
        </Box>
    );
});