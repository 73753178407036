import config from "./config.json";

export const dynamicConfig ={
    backend: {
        host:
            window.location.hostname === "localhost"
                ?
                "http://localhost:8000"
                :
                config.backendSettings.baseUrl || window.location.origin
    }
}