import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    BottomNavigation,
    Box,
    CardActionArea,
    Chip,
    CircularProgress,
    Divider,
    Pagination,
    Paper,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import {ProjectState} from "../state";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from "@mui/material/CardMedia";
import config from "../config.json";
import {BackButton} from "@vkruglikov/react-telegram-web-app";
import {observer} from "mobx-react-lite";
import {runInAction, toJS} from "mobx";

const projectState = new ProjectState()
Array.prototype.random = function () {
    return this[Math.floor((Math.random() * this.length))];
}

export const ProductsList = observer(() => {
    const [query, setQuery] = useSearchParams()
    const navigate = useNavigate();
    const categoryObj = config.categories[query.get("category")]
    let page = query.get("page");
    page = page ? Number(page) : 1;
    projectState.curSpecificBank = query.get("bank");

    const Category = () => {
        return (
            <Stack direction={"row"} marginBottom={"1vh"}>
                <Typography
                    variant={"bold"}
                    style={{alignSelf: "center"}}
                    color={"gray"}
                >
                    {categoryObj?.name}
                </Typography>
            </Stack>
        )
    };

    const ProductItem = observer(({product, productsLoading}) => {
        const [imageLoading, setImageLoading] = useState(true);
        return (<>
                {productsLoading ?
                    <Box>{[...Array(5)].map(() => <Skeleton
                        variant="text" sx={{fontSize: '3rem'}}/>)}</Box>
                    :
                    <Card elevation={4} style={{marginBottom: "2vh"}}>
                        <CardActionArea
                            onClick={() => {
                                navigate(`/products/${product?.id}/?category=${query.get("category")}&bank=${query.get("bank")}`)
                            }}
                        >
                            <CardContent style={{display: "flex", minHeight: "20vh"}}>
                                <Box height={"100%"} alignSelf={"center"}>
                                    <Box
                                        width={"15vw"}
                                        display={imageLoading ? "flex" : "none"}
                                        justifyContent={"center"}
                                        height={"100%"}
                                        alignItems={"center"}
                                        alignSelf={"center"}
                                    >
                                        <CircularProgress/>
                                    </Box>
                                    <CardMedia
                                        style={{
                                            display: imageLoading ? "none" : "block",
                                            animation: "fadeIn 0.5s",
                                            height: "100%",
                                            width: "20vw",
                                            objectFit: "contain",
                                            alignSelf: "center"
                                        }}
                                        component={"img"}
                                        image={"https://agents.pampadu.ru" + product?.icon}
                                        onLoad={(e) => {
                                            setImageLoading(false)
                                        }}
                                    />
                                </Box>
                                <Divider orientation={"vertical"} style={{margin: "0 1vh"}} flexItem/>
                                <Stack margin={"1vh"} width={"100%"}>
                                    <Box
                                        justifyContent="center"
                                        width={"100%"}
                                        height={"100%"}
                                    >
                                        <Typography fontSize={"1em"}>
                                            {product?.title}
                                        </Typography>
                                    </Box>
                                    <Chip
                                        sx={{boxShadow: 3}}
                                        style={{alignSelf: "center", fontSize: "1em"}}
                                        label={
                                            <Typography
                                                variant={"bold"}
                                                fontSize={"1em"}
                                            >
                                                {`Подарок${['🎉', '🚀', '🥂'].random()}${product?.commission} руб.`}
                                            </Typography>
                                        }
                                    />
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                }
            </>
        );
    });

    useEffect(() => {
        projectState.userTgId = window.Telegram.WebApp.initDataUnsafe.user.id;
        projectState.getProducts(
            "allForEach",
            query.get("category"),
            page,
            3,
            query.get("bank")
        ).then(
            (result) => {
                projectState.curSpecificBankProducts = result;
            }
        )
    }, [null, query, projectState.userTgId]);

    const handlePageChange = (event, value) => {
        projectState.productsLoading = true;
        query.set("page", value);
        setQuery(query);
    };

    const BottomPagination = () => {
        const ObservedPagination = observer(
            () => {
                let count = Math.ceil(projectState.productsPageData.general / projectState.productsPageData.size)
                return <Pagination
                    count={count}
                    page={toJS(projectState.productsPageData.page)}
                    onChange={handlePageChange}
                    style={{alignSelf: "center"}}
                />
            }
        )
        return (
            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                <BottomNavigation>
                    <ObservedPagination/>
                </BottomNavigation>
            </Paper>
        );
    };

    return <Box margin={"2vh"}>
        <Stack>
            <BackButton
                onClick={() => navigate(`/banks/?category=${query.get("category")}&viewType=allForEach`)}/>
            <Typography variant={"h4"}>{query.get("bank")}</Typography>
            <Divider/>
            <Category/>
        </Stack>
        {
            projectState.productsLoading ?
                <Box>{[...Array(5)].map(() => <Skeleton variant={"text"} sx={{fontSize: '3rem'}}/>)}</Box>
                :
                projectState.curSpecificBankProducts?.map((product, index) => <ProductItem product={product}
                                                                           productsLoading={projectState.curSpecificBankProductsLoading}/>)
        }
        <BottomPagination/>
    </Box>;
});
