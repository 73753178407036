import {createTheme} from "@mui/material";

const colors = {
    primary: "#ffab00"
}

const theme = createTheme({
    typography: {
        fontFamily: "Tinkoff Sans",
        button: {
            textTransform: 'none'
        }
    },
    palette: {
        primary: {
            main: colors.primary,
        },
        secondary: {
            main: '#f50057',
        },
        text: {
            main: "black",

        },
        background: {
            default: "#f5f5f5"
        }
    },
});

export default theme;