import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {ProjectState} from "../state";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import axios from "axios";
import {dynamicConfig} from "../dynamicConfig";
import {Box, Chip, CircularProgress, Divider, Fade, Skeleton, Stack, Typography} from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import config from "../config.json";

const projectState = new ProjectState();
Array.prototype.random = function () {
    return this[Math.floor((Math.random() * this.length))];
}

export const ProductItem = observer(() => {
    const {productItemId} = useParams()
    const [query, setQuery] = useSearchParams()
    const [productLoading, setProductLoading] = useState(false);
    const [imageLoading, setImageLoading] = React.useState(true);
    const navigate = useNavigate();

    function updateProduct() {
        if (projectState.currentProduct?.id === productItemId && !productLoading) return
        setProductLoading(true);
        const params = {
            product_type: query.get("category"),
            user_id: projectState.userTgId
        }
        axios.get(dynamicConfig.backend.host + `/api/v1/products/${productItemId}`, {params})
            .then(
                (response) => {
                    projectState.currentProduct = response.data;
                    setProductLoading(false)
                },
                (error) => {
                    console.log(error)
                    setProductLoading(false)
                }
            )
    }

    useEffect(() => {
        updateProduct();
    }, [])

    return (
        <Box
            display="flex"
            justifyContent="center"
            margin={"2vh"}
        >
            {
                <>
                    <BackButton onClick={() => navigate(-1)}/>
                    <MainButton text={"Оформить заявку"} onClick={() => {
                        window.Telegram.WebApp.openLink(projectState.currentProduct.userRefLink)
                    }}/>
                </>
            }
            <Stack style={{marginBottom: "2vh"}} width={"100%"}>
                {
                    productLoading ?
                        <Fade
                            in={productLoading}
                            style={{
                                transitionDelay: productLoading ? '800ms' : '0ms',
                            }}
                            unmountOnExit
                        >
                            <div style={{margin: "2vh"}}>
                                {[...Array(5)].map(() => <Skeleton variant="text" sx={{fontSize: '3rem'}}
                                                                   width={"100%"}/>)}
                            </div>
                        </Fade>
                        :
                        <>
                            <Typography gutterBottom variant="h6">
                                {projectState.currentProduct?.title}
                            </Typography>
                            <Divider/>
                            <Box marginBottom={"2vh"}>
                                <Typography color={"gray"}>
                                    {config.categories[query.get("category")]?.goToText}
                                </Typography>
                            </Box>
                            <Card elevation={3}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignContent={"center"}
                                    margin={"5vh 5vh 0 5vh"}
                                >
                                    <CircularProgress
                                        style={{display: !imageLoading ? "none" : "block", animation: "fadeIn 0.5s"}}/>
                                    <img
                                        style={{
                                            display: imageLoading ? "none" : "block",
                                            width: "100%",
                                            animation: "fadeIn 0.5s"
                                        }}
                                        src={"https://agents.pampadu.ru" + projectState.currentProduct?.icon}
                                        onLoad={(e) => {
                                            setImageLoading(false)
                                        }}
                                    />
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    margin={"2vh"}
                                >
                                    <Chip style={{padding: "1vh"}} style={{fontSize: "1.2em"}}
                                          label={`💵 Подарок ${projectState.currentProduct?.commission} рублей`}/>
                                </Box>
                                <Divider/>
                                <CardContent>
                                    {projectState.currentProduct?.keyAdvantages.map(
                                        (adv) => <Typography
                                            fontSize={"1.1em"}>{["👍", "👌", "👏"].random()} {adv.title}</Typography>
                                    )}
                                </CardContent>
                                <Accordion elevation={0}
                                           style={{marginTop: "3vh", borderTop: "3px lightgray solid"}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography color={"gray"}>Дополнительные преимущества</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {projectState.currentProduct?.additionalAdvantages.map(
                                            (adv) => <Stack direction={"row"}>
                                                •<Typography style={{marginLeft: "0.4em"}} fontSize={"1.1em"}>
                                                {adv}
                                            </Typography>
                                            </Stack>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            </Card>
                        </>
                }

            </Stack>
        </Box>
    );
});