import React from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {Box, Divider, List, ListItemButton, Paper, Stack, Typography} from "@mui/material";
import config from "../config.json";
import {BackButton} from "@vkruglikov/react-telegram-web-app";

const isTouchScreenDevice = () => {
    try {
        document.createEvent('TouchEvent');
        return true;
    } catch (e) {
        return false;
    }
}

export const ProductsNavigationPage = (props) => {
    const [query] = useSearchParams()
    const navigate = useNavigate();
    const categoryObj = config.categories[query.get("category")]
    const Category = () => (
        <Stack direction={"row"}>
            <Typography
                variant={"h4"}
                style={{alignSelf: "center"}}
            >
                {categoryObj?.name}
            </Typography>
        </Stack>
    );

    return (
        <Box>
            <BackButton onClick={() => navigate(`/`)}/>
            <Box justifyContent={"center"} margin={"2vh"}>
                <Category/>
                <Divider/>
                <Box marginBottom={"2vh"}>
                    <Typography color={"gray"}>
                        {config.mainPage.title}
                    </Typography>
                </Box>
                <Paper>
                    <List margin={"2vh 0"}>
                        <ListItemButton
                            onClick={() => navigate(`/banks/?category=${query.get("category")}&viewType=bestForEach`)}
                        >
                            <Stack direction={"row"}>
                                <Typography variant={"h5"} style={{marginRight: "1vh", alignSelf: "center"}}>
                                    {config.navigation.oneBestProductForEachBank.icon}
                                </Typography>
                                <Typography variant={"bold"} style={{alignSelf: "center", color: "black"}}>
                                    {config.navigation.oneBestProductForEachBank.goToText}
                                </Typography>
                            </Stack>
                        </ListItemButton>
                        <Divider style={{margin: "0 2vw"}}/>
                        <ListItemButton
                            onClick={() => navigate(`/banks/?category=${query.get("category")}&viewType=allForEach`)}>
                            <Stack direction={"row"}>
                                <Typography variant={"h5"} style={{marginRight: "1vh", alignSelf: "center"}}>
                                    {config.navigation.allProductsForEachBank.icon}
                                </Typography>
                                <Typography variant={"bold"} style={{alignSelf: "center", color: "black"}}>
                                    {config.navigation.allProductsForEachBank.goToText}
                                </Typography>
                            </Stack>
                        </ListItemButton>
                    </List>
                </Paper>
            </Box>
        </Box>
    );
};